import Strings from "./Strings";
import { isLocalEnvironment, isStagingEnvironment } from "./Util";

const prefix = isLocalEnvironment() ? "" : isStagingEnvironment() ? "Staging_" : "Key_"

export const clearItem = (key) => {
    try {
        localStorage.removeItem(prefix + Strings.STORAGE_KEY + key);
    } catch (error) {
        console.log(error.message);
    }
};

export const storeItem = (key, value) => {
    try {
        localStorage.setItem(prefix + Strings.STORAGE_KEY + key, value);
    } catch (error) {
        console.log(error.message);
    }
};

export const retrieveItem = key => {
    let item = null;
    try {
        item = localStorage.getItem(prefix + Strings.STORAGE_KEY + key) || null;
    } catch (error) {
        console.log(error.message);
    }
    return item;
};
