import React, { useState } from "react";
import OrangeButton from "../../components/Common/OrangeButton";
// import OrangeButton from '../../components/Common/OrangeButton'

function EventFilter({
  search,
  selectedCategory,
  searchPlace,
  handleSearch,
  handleCategoryChange,
  handleSearchPlace,
}) {
  return (
    <>
      <div className="bg-darkNavyBlue container relative sm:items-stretch sm:justify-start mx-auto max-w-6xl h-[95px] flex-shrink-0 rounded-3xl">
        <div className="flex flex-row space-x-24  ml-8 mr-2 mx-auto items-center justify-center p-2">
          <div className="flex flex-col gap-3">
            <label
              htmlFor="searchEvent"
              className="text-white font-roboto text-base font-normal leading-6"
            >
              Search Event
            </label>
            <input
              value={search}
              onChange={handleSearch}
              className="w-64 bg-darkNavyBlue  focus:outline-none border-b-[0.8px] border-blueBell text-white placeholder-white font-roboto text-base font-semibold leading-7 p-1"
              placeholder="The Purple Party"
            />
          </div>          
          <div className="flex flex-col gap-3">
            <label
              htmlFor="category"
              className="text-white font-roboto text-base font-normal leading-6"
            >
              Category
            </label>
            <div className="relative">
              <select
                value={selectedCategory}
                onChange={handleCategoryChange}
                id="category"
                className="w-64 bg-darkNavyBlue focus:outline-none border-b-[0.8px] border-blueBell text-white font-roboto text-base font-semibold leading-7 appearance-none  p-1"
              >
                <option value="">Choose Category</option>
                <option value="Health">Health</option>
              <option value="Fashion">Fashion</option>
              <option value="Government">Government</option>
              <option value="Technology">Technology</option>
              <option value="Corporate and Business">Corporate and Business</option>
              <option value="Music and Concerts">Music and Concerts</option>
              <option value="Home and Appliances">Home and Appliances</option>
              <option value="Food and Drink">Food and Drink</option>
              <option value="Cause and Charity">Cause and Charity</option>
              <option value="Women and Youth">Women and Youth</option>
              <option value="Sports">Sports</option>
              <option value="Art">Art</option>
              <option value="Cinema & Movie">Cinema & Movie</option>
              <option value="Religious">Religious</option>
              <option value="Entrepreneurship">Entrepreneurship</option>

              </select>
              <svg
                className="absolute right-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-white pointer-events-none"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M19 9l-7 7-7-7"></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <label
              htmlFor="place"
              className="text-white font-roboto text-base font-normal leading-6"
            >
              Place
            </label>
            <input
              value={searchPlace}
              onChange={handleSearchPlace}
              className="w-64 bg-darkNavyBlue  focus:outline-none border-b-[0.8px] border-blueBell text-white placeholder-white font-roboto text-base font-semibold leading-7  p-1"
              placeholder="Lavington"
            />
          </div>

          {/* Ask if the filter button is necessary */}
          {/* <div className='flex flex-col gap-6'>
            <OrangeButton buttonName='Filter' /> 
          </div> */}
        </div>
      </div>
    </>
  );
}

export default EventFilter;
