import React from "react";

function Vendors() {
  return (
    <>
      
      <div>Vendors</div>
    </>
  );
}

export default Vendors;
