export const isStagingEnvironment = () => window.location.host.includes("client")

export const isLocalEnvironment = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || window.location.host.includes("dev")

export const truncate = (str, n)  => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
}

export const capitalize = (sentence) => {
    if (typeof sentence !== 'string' || !sentence.trim()) return '';
  
    return sentence
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

export const addEllipsisAfter40Words = (text) =>{
    // Split the text into words
    const words = text.split(' '); 
   
    if (words.length <= 40) {
      return text;
    } 
    
    const truncatedText = words.slice(0, 60).join(' ');
    return truncatedText + '...';
  }



export const formattedDate = (newDate) => {
  const date = new Date(newDate);
  
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
 
  const weekdayIndex = date.getDay();
  
  const weekdayAbbreviation = weekdays[weekdayIndex];
 
  const monthName = date.toLocaleString('en-US', { month: 'short' });
  
  const dayOfMonth = date.getDate();

  const getOrdinalSuffix = (day) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };
 
  const formattedDayOfMonth = getOrdinalSuffix(dayOfMonth);

  const formattedDate = `${weekdayAbbreviation}, ${monthName} ${formattedDayOfMonth}, ${date.getFullYear()}`;

  return formattedDate;
};


