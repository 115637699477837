import { useState } from "react";

export const useFilterHooks = () => {
    const [search, setSearch] = useState("")
    const [searchPlace, setSearchPlace] = useState('')
    const [selectedCategory, setSelectedCategory] = useState('');

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const handleSearchPlace = (e) => {
        setSearchPlace(e.target.value)
    }

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    }

    return {
        search,
        setSearch,
        searchPlace,
        setSearchPlace,
        selectedCategory,
        setSelectedCategory,
        handleSearch,
        handleSearchPlace,
        handleCategoryChange,
      };
}