import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
import { capitalize } from '../../util/Util'
import mpesa from '../../images/mpesa.png'
import visa from '../../images/visa.png'
import { confirmInfo } from '../../util/Payment'

function EventCheckoutSingle() {
  const location = useLocation()
  const ticketTypeArray = location.state
  let ticketCount = ticketTypeArray[0].totalCount;
  let ticketType = ticketTypeArray[0].ticketType;
  let totalTicketPrice = ticketTypeArray[0].totalPrice;
  let eventName = ticketTypeArray[0].eventName;
  let eventID = ticketTypeArray[0].eventID;
  let ticketID = ticketTypeArray[0].ticketID;
  let ticketTypeID = ticketTypeArray[0].ticketTypeID;
  let ticketPrice = ticketTypeArray[0].ticketPrice;


  const [showInput, setShowInput] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = () => {
    setShowInput(!showInput); // Toggle the state when the <h4> is clicked
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };


  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [postalAddress, setPostalAddress] = useState('')
  const [physicalAddress, setPhysicalAddress] = useState('')
  const [promoCode, setPromoCode] = useState('')
  const [gender, setGender] = useState('');
  const fullName = `${firstName} ${lastName}`;

  const handleSubmit = (e) => {
    e.preventDefault()

    if (selectedImage && selectedImage === 'mpesa') {
      const userData = {
        eventid: eventID,
        eventname: eventName,
        ttype: ticketType,
        tid: ticketID,
        tickettypeid: ticketTypeID,
        tnumber: ticketCount,
        tprice: ticketPrice,
        tseats: null,
        tuserid: "user_1",
        tamount: totalTicketPrice,
        email: email,
        name: `${firstName} ${lastName}`,
        number: phoneNumber,
        company: "GiG",
        occupation: "Support",
        postaladdress: postalAddress,
        physicaladdress: physicalAddress,
        secondname: lastName,
        officephonenumber: '070000000',
        gender: gender,
        age: null,
        treferall: promoCode ? promoCode : "Gig",
        children: [fullName],
        tinsured: false,
        pay_via: selectedImage,
        order_id: "G269208"
      }

      confirmInfo(userData);   
           
    }


  }



  return (
    <>
    <div className='container mx-auto relative max-w-[1536px]'>
    <form onSubmit={handleSubmit}>
        <div className='flex flex-row pt-10 gap-32'>
          <div className='flex flex-col space-y-2  pl-48'>
            <div className='flex flex-col gap-3'>
              <p className="font-roboto font-semibold text-xl leading-[150%] text-primaryBlue">Checkout</p>
              <div className='flex flex-row space-x-2 bg-infoGrey items-center justify-center h-10 w-[600px] rounded-lg'>
                <FontAwesomeIcon icon={faCircleInfo} className=' text-infoBlue' />
                <p className='font-roboto text-sm font-normal leading-[150%] text-infoBlue'>We’ve reserved your tickets please complete checkout within 09:24 to secure your tickets.</p>
              </div>
            </div>
            <div className='flex flex-col gap-3 p-2'>
              <p className='font-roboto text-base font-normal leading-[150%]'>Where should we send your booking confirmation?</p>
              <div className='grid gap-6 mb-6 md:grid-cols-2'>
                <div className='flex flex-col '>
                  <label htmlFor="email" className='text-primaryBlack font-roboto text-base font-medium leading-6 uppercase'>Email<span className='text-secondaryRed'>*</span></label>
                  <input required type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className='items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg' placeholder='ricky@gmail.com' />
                </div>
                <div className='flex flex-col'>
                  <label htmlFor="phone" className='text-primaryBlack font-roboto text-base font-medium leading-6'>Phone Number<span className='text-secondaryRed'>*</span></label>
                  <input required type="tel" id="phone" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className='items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg' placeholder='+254 700 000 000' />
                </div>
              </div>

            </div>
            <div className='flex flex-col gap-2 p-2'>
              <p className="font-roboto font-medium text-medium leading-[150%] text-primaryBlue">Personal Details</p>
              <div className='grid gap-6 mb-6 md:grid-cols-2'>
                <div className='flex flex-col '>
                  <label htmlFor="name" className='text-primaryBlack font-roboto text-base font-medium leading-6'>First Name<span className='text-secondaryRed'>*</span></label>
                  <input required type="text" id="first_name" value={firstName} onChange={(e) => setFirstName(e.target.value)} className='items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg' placeholder='John' />
                </div>
                <div className='flex flex-col'>
                  <label htmlFor="name" className='text-primaryBlack font-roboto text-base font-medium leading-6'>Last Name<span className='text-secondaryRed'>*</span></label>
                  <input required type="text" id="last_name" value={lastName} onChange={(e) => setLastName(e.target.value)} className='items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg' placeholder='Doe' />
                </div>
                <div className='flex flex-col '>
                  <label htmlFor="postal_address" className='text-primaryBlack font-roboto text-base font-medium leading-6'>Postal Address<span className='text-secondaryRed'>*</span></label>
                  <input required type="text" id="postal_address" value={postalAddress} onChange={(e) => setPostalAddress(e.target.value)} className='items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg' placeholder='01000' />
                </div>
                <div className='flex flex-col'>
                  <label htmlFor="address" className='text-primaryBlack font-roboto text-base font-medium leading-6'>Physical Address<span className='text-secondaryRed'>*</span></label>
                  <input required type="text" id="physical_address" value={physicalAddress} onChange={(e) => setPhysicalAddress(e.target.value)} className='items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg' placeholder='Nairobi' />
                </div>
                <div className='flex flex-col'>
                  <p className='text-primaryBlack font-roboto text-base font-medium leading-6'>Gender</p>
                  <div className='flex flex-row gap-6'>
                    <div
                      onClick={() => setGender('Male')}
                      className={gender === 'Male' ? 'items-center justify-center border rounded-lg cursor-pointer border-primaryBlack text-secondaryBlue  text-md p-2 font-roboto bg-lavendarBlue' : 'items-center justify-center border rounded-lg cursor-pointer border-primaryBlack text-primaryBlack rounded-lg cursor-pointer text-md p-2 font-roboto hover:text-secondaryBlue hover:border-secondaryBlue'}
                    >
                      <span className="ml-2">Male</span>
                    </div>
                    <div
                      onClick={() => setGender('Female')}
                      className={gender === 'Female' ? 'items-center justify-center border rounded-lg cursor-pointer border-primaryBlack text-secondaryBlue  text-md p-2 font-roboto bg-lightPastel' : 'items-center justify-center border rounded-lg cursor-pointer border-primaryBlack text-primaryBlack rounded-lg cursor-pointer text-md p-2 font-roboto hover:text-secondaryBlue hover:border-secondaryBlue'}
                    >
                      <span className="ml-2">Female</span>
                    </div>                    
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-5'>
            <div className='flex flex-col'>
              <p className="font-roboto font-medium text-base leading-[150%] text-primaryBlue">Summary</p>
              <div className='p-4 w-[400px] h-auto flex-shrink-0 rounded-lg mt-5 flex flex-col space-y-4 bg-infoGrey'>
                <h4 className='font-roboto text-md font-semibold leading-6 text-primaryBlue'>{capitalize(eventName)}</h4>
                <h4 className='font-roboto text-sm font-semibold leading-6 text-secondaryRed underline cursor-pointer' onClick={() => handleClick()}>Add Promo Code?</h4>
                {showInput && (
                  <input
                    type="text"
                    id="promo_code"
                    className="items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg"
                    placeholder="PROMO CODE"
                    value={promoCode} onChange={(e) => setPromoCode(e.target.value)}
                  />
                )}
                <div className='flex flex-row '>
                  <p className='text-secondaryBlack font-roboto text-sm font-normal leading-[150%] flex-grow'>{ticketCount} x {ticketType}</p>
                  <p className='text-secondaryBlack font-roboto text-sm font-normal leading-[150%]'>KES {totalTicketPrice.toLocaleString()}</p>
                </div>

                <hr className="bg-primaryBlack h-0.5 w-[360px] mx-auto" />
                <p className='text-primaryBlue font-roboto text-base font-semibold leading-[150%] text-right'>Total: KES {totalTicketPrice.toLocaleString()}</p>



              </div>
            </div>

            <div className='flex flex-col gap-4'>
              <p className="font-roboto font-medium text-base leading-[150%] text-primaryBlue">Payment Information</p>
              <div className='flex flex-row gap-8'>
                <img src={mpesa} alt='mpesa' onClick={() => handleImageClick('mpesa')} className={selectedImage === 'mpesa' ? 'border-2 border-black rounded-lg' : 'rounded-lg'} />
                <img src={visa} alt='visa' onClick={() => handleImageClick('visa')} className={selectedImage === 'visa' ? 'border-2 border-black rounded-lg' : 'rounded-lg'} />
              </div>
            </div>

            <button className="bg-customOrange text-white hover:text-white font-bold py-2 px-6 rounded-lg text-base"
              type="submit"
              disabled={!selectedImage} // Disable button if no image is selected
            >
              Complete Payment
            </button>

          </div>
        </div>
      </form>
    </div>
      
    </>
  )
}

export default EventCheckoutSingle
