import React, { useEffect, useState } from "react";
import { capitalize } from "../../util/Util";
import { useEvents } from "../../hooks/useEvents";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faClock } from "@fortawesome/free-regular-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import EventSummary from "./EventSummary";
import EventTicket from "./EventTicket";
import {
  calculateTotalAmount,
  updateTicketData,
  getTicketTypeCountArray,
} from "../../util/TicketData";
import EventDetailsSkeleton from "./EventDetailsSkeleton";
import EventCardSkeleton from "./EventCardSkeleton";
import EventRow from "./EventRow";

function EventDetails() {
  const { loadEvent, event, isLoading, popularEvents } = useEvents();
  const id = useParams();
  const [ticketData, setTicketData] = useState({});
  const navigate = useNavigate();



  const handleTicketChange = (
    type,
    count,
    amount,
    eventID,
    ticketID,
    ticketTypeID
  ) => {
    updateTicketData(
      type,
      count,
      amount,
      eventID,
      ticketID,
      ticketTypeID,
      ticketData,
      setTicketData
    );
  };

  const totalAmount = calculateTotalAmount(ticketData);
  const ticketTypeCountArray = getTicketTypeCountArray(
    ticketData,
    event.eventname
  );
  const ticketTypeCount = Object.keys(ticketData).length; //count for each type of ticket eg. advanced, vip

  useEffect(() => {
    loadEvent(id);
  }, [id]);

  let ticketTypes = null;

  if (event && event.tickettypes) {
    ticketTypes = event.tickettypes.map((ticket, index) => (
      <EventTicket
        key={ticket.id}
        ticket={ticket}
        index={index}
        handleTicketChange={handleTicketChange}
      />
    ));
  }

  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    event.eventlocation
  )}`;

  return (
    <>
      {isLoading ? (
        <EventDetailsSkeleton />
      ) : (
        <>
          <div className="container relative mx-auto max-w-7xl mt-10 h-3/4 ">
            <div className="h-auto flex flex-row space-x-6 ">
              <div className="p-5 flex flex-col w-1/2 space-y-2 ml-[90px]">
                <div>
                  <div className="p-1">
                    <p className="text-primaryBlue font-roboto font-medium text-xl text-[23px]">
                      {capitalize(event.eventname, 20)}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="p-1">
                    <p className="flex flex-row space-x-4">
                      <span>
                        <FontAwesomeIcon
                          icon={faCalendarDays}
                          size="lg"
                          className="text-customGrey"
                        />
                      </span>
                      <span className="font-roboto text-md font-medium leading-relaxed text-customGrey">
                        {event.eventstartdate}
                      </span>
                    </p>
                  </div>
                  <div className="p-1">
                    <p className="flex flex-row space-x-4">
                      <span>
                        <FontAwesomeIcon
                          icon={faClock}
                          size="lg"
                          className="text-customGrey"
                        />
                      </span>
                      <span className="font-roboto text-md font-medium leading-relaxed text-customGrey">
                        {event.eventtime}
                      </span>
                    </p>
                    <div className="p-1">
                      <p className="flex flex-row space-x-4">
                        <span>
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            size="lg"
                            className="text-customGrey"
                          />
                        </span>
                        <span className="font-roboto text-md font-medium leading-relaxed text-denimBlue underline hover:underline-offset-4">
                          <a
                            href={mapsUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {event.eventlocationname}
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    className=" rounded-lg min-h-full max-w-md max-h-[410px] w-[360px] h-[420px] object-fill p-1"
                    src={`https://client.gig.co.ke/eventpics/${event.eventid}_banner_pic`}
                    alt="event poster"
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-4 ">
                <div className="space-y-2 w-[700px] h-[120px] mt-6">
                  <h3 className="text-primaryBlue font-roboto font-semibold leading-6 text-base">
                    About Event
                  </h3>
                  <EventSummary event={event} />
                </div>
                <div className=" h-[300px] w-[520px]">
                  <h3 className="text-primaryBlue font-roboto font-semibold leading-6 text-base">
                    Tickets Available
                  </h3>
                  <div >
                    <div className="grid grid-cols-3 gap-2">
                    {ticketTypes}
                    </div>
                  
                  </div>
                </div>
                <div className="flex flex-col space-y-4 ">
                  <div className="bg-primaryBlack h-12  rounded-lg flex flex-row">
                    <p className="flex-grow text-customWhite font-roboto text-xl font-medium leading-6 p-3">
                      Total Amount
                    </p>
                    <p className="p-3 text-customWhite font-roboto text-xl font-medium leading-6">
                      KES {totalAmount.toLocaleString()}
                    </p>
                  </div>
                  {ticketTypeCount === 1 ? (
                    <button
                      className="bg-customOrange text-white hover:text-white font-bold py-2 px-6 rounded-lg text-base"
                      onClick={() =>
                        navigate("/checkout/single", {
                          state: ticketTypeCountArray,
                        })
                      }
                    >
                      Continue To Check Out
                    </button>
                  ) : ticketTypeCount > 1 ? (
                    <button
                      className="bg-customOrange text-white hover:text-white font-bold py-2 px-6 rounded-lg text-base"
                      onClick={() =>
                        navigate("/checkout/multiple", {
                          state: ticketTypeCountArray,
                        })
                      }
                    >
                      Continue To Check Out
                    </button>
                  ) : (
                    <button
                      disabled={true}
                      className="bg-secondaryOrange text-white hover:text-white font-bold py-2 px-6 rounded-lg text-base"
                    >
                      Continue To Check Out
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="p-2 mt-8">
      {isLoading ? (
          <div
            className={`skeleton-wrapper ${isLoading ? "" : "skeleton-hidden"}`}
          >
            <EventCardSkeleton />
          </div>
        ) : (
          <div
            className={`skeleton-wrapper ${isLoading ? "skeleton-hidden" : ""}`}
          >
            <div
              className={`event-row ${isLoading ? "" : "event-row-visible"}` }
            >
              <EventRow title="Related Events" events={popularEvents} titleStyle={{ marginLeft: '20px' }}/>
            </div>
          </div>
        )}
      </div>
      
    </>
  );
}

export default EventDetails;
