import React from "react";
import purple_party from "../../images/POSTERS=Purple party.png";
import OrangeButton from "../../components/Common/OrangeButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faGreaterThan,
  faLessThan,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { addEllipsisAfter40Words } from "../../util/Util";

function Carousel() {
  // function NextArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block", background: "black" }}
  //       onClick={onClick}
  //     >
  //       <FontAwesomeIcon icon={faGreaterThan} />
  //     </div>
  //   );
  // }

  // function PrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block", background: "black" }}
  //       onClick={onClick}
  //     >
  //       <FontAwesomeIcon icon={faLessThan} />
  //     </div>
  //   );
  // }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    lazyLoad: true,
    // prevArrow: <PrevArrow />,
    // nextArrow: <NextArrow />,
  };

  let text =
    "Prepare to be captivated by a mesmerizing spectacle of vibrant décor, featuring lush greenery cascading from towering palms, exotic orchids bursting with color, and a symphony of purple accents that will transport you to an island paradise. As you step into this enchanting realm, let the pulsating rhythms of tropical beats guide your every move.";

  const content = (
    <div className="container relative flex justify-between mx-auto w-[388px] sm:w-[1536px] sm:max-w-[1536px] sm:h-[600px] flex-shrink-1 sm:mt-16 mt-6">
      <div className="z-10 container flex flex-col sm:flex-row justify-center sm:p-5 sm:space-y-3 space-x-4 w-screen">
        <div className=" sm:w-1/3 sm:justify-end sm:mr-12 p-3 lg:p-2 flex justify-center">
          <img
            src={purple_party}
            alt="dashboard_image"
            className="max-w-[600px] sm:w-[340px] sm:h-[375px] rounded-lg w-[300px] "
          />
        </div>
        <div className="sm:w-1/3 flex flex-col space-y-2 justify-center items-center sm:justify-start sm:items-start sm:space-y-6">
          <div className="p-1 ">
            <p className="text-primaryBlue font-roboto font-semibold text-[18px] sm:text-[28px]">
              Tropical Purple Party
            </p>
          </div>
          <div className="space-y-1 p-1 flex flex-col items-center justify-center sm:justify-start sm:items-start">
            <div className="">
              <p className="sm:space-x-4 space-x-3">
                <span>
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    size="lg"
                    className="text-customGrey"
                  />
                </span>
                <span className="font-roboto text-base font-medium leading-relaxed text-primaryBlue">
                  Tue, Nov 16th, 2023
                </span>
              </p>
            </div>
            <div className="">
              <p className="sm:space-x-4 space-x-3">
                <span>
                  <FontAwesomeIcon
                    icon={faClock}
                    size="lg"
                    className="text-customGrey"
                  />
                </span>
                <span className="font-roboto text-base font-medium leading-relaxed text-primaryBlue">
                  04:00 pm - 11:00 pm
                </span>
              </p>
            </div>
            <div className="">
              <p className="sm:space-x-4 space-x-3">
                <span>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    size="lg"
                    className="text-customGrey"
                  />
                </span>
                <span className="font-roboto text-base font-medium leading-relaxed text-primaryBlue">
                  Ngong' Racecourse
                </span>
              </p>
            </div>
            <div className="p-1 hidden md:flex md:h-[144px] md:w-[540px]">
            <p className="text-secondaryBlack font-roboto text-[11px] sm:text-[13px] font-normal leading-relaxed sm:mt-[20px]">
              {addEllipsisAfter40Words(text)}      
            </p>
          </div>
          <div className="p-2 ">
            <OrangeButton
              buttonName="Get Tickets"             
              height="48px"
              width="160px"
            />
          </div>


          </div>
        </div>
      </div>
    </div>
  );

  const duplicatedContent = [
    content,
    content,
    content,
    content,
    content,
    content,
  ];

  return (
    <>
      <Slider {...settings}>
        {duplicatedContent.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </Slider>
    </>
  );
}

export default Carousel;
