
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    STORAGE_KEY: 'GiG_Front_Key',
    APP_ID: 'GiG-Front-App',
    CONNECTION_ERROR_MESSAGE: 'Cannot connect at this time',
    APP_DESCRIPTION: "GiG is a self-service ticketing platform for live experiences that allows anyone to create, share, find and attend events that fuel their passions and enrich their lives." ,
    // GUARD_NAME:'admin'
    PLAYSTORE_LINK: 'https://play.google.com/store/apps/details?id=com.guiderigevents.app&pcampaignid=web_share',
    APPSTORE_LINK: 'https://apps.apple.com/ke/app/gig-events/id6469293691',
    APP_DOWNLOAD_DESCRIPTION: 'Download the Gig app to easily buy event tickets fast whenever you need.'
};
