import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Dashboard from "./pages/Dashboard/Dashboard";
import { Toaster } from "react-hot-toast";
import { useAuth } from "./hooks/auth";
import { authRoutes, userNonAuthRoutes, userRoutes } from "./routes/allRoutes";
import AuthMiddleware from "./routes/AuthMiddleware";

const App = () => {
  const { isLoggedIn, isDoneChecking } = useAuth(); 


  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          {userRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <AuthMiddleware
                  component={route.component}
                  isAuthProtected={true}
                  isLoggedIn={isLoggedIn}
                  isDoneChecking={isDoneChecking}
                />
              }
            />
          ))}
        </Route>

        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          {userNonAuthRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <AuthMiddleware
                component={route.component}
                isAuthProtected={false}
                isLoggedIn={isLoggedIn}
                isDoneChecking={isDoneChecking}
                />
              }
            />
          ))}
        </Route>

        {authRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthMiddleware
                component={route.component}
                isAuthProtected={false}
                isLoggedIn={isLoggedIn}
                isDoneChecking={isDoneChecking}
              />
            }
          />
        ))}
      </Routes>
    </>
  );
};

export default App;
