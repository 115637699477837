import React from "react";
import "./dashboard.css";
import EventRow from "../Events/EventRow";
import { useEvents } from "../../hooks/useEvents";
import EventFilter from "../Events/EventFilter";
import backgroundImage from "../../images/bg.jpg";
import EventLink from "../Events/EventLink";
import AreYouAnEventOrganizer from "../Events/AreYouAnEventOrganizer";
import { useFilteredEvents } from "../../util/Filter";
import { useFilterHooks } from "../../hooks/filterHooks";
import EventCardSkeleton from "../Events/EventCardSkeleton";
import Carousel from "../Events/Carousel";

function Dashboard() {
  const {
    popularEvents,
    upcomingEvents,
    weekendEvents,
    featuredEvents,
    musicEvents,
    isLoading,
  } = useEvents();

  const {
    search,
    searchPlace,
    selectedCategory,
    handleSearch,
    handleSearchPlace,
    handleCategoryChange,
  } = useFilterHooks();

  const popularFilteredEvents = useFilteredEvents(
    popularEvents,
    search,
    searchPlace,
    selectedCategory
  );
  const upcomingFilteredEvents = useFilteredEvents(
    upcomingEvents,
    search,
    searchPlace,
    selectedCategory
  );
  const weekendFilteredEvents = useFilteredEvents(
    weekendEvents,
    search,
    searchPlace,
    selectedCategory
  );
  const featuredFilteredEvents = useFilteredEvents(
    featuredEvents,
    search,
    searchPlace,
    selectedCategory
  );
  const musicFilteredEvents = useFilteredEvents(
    musicEvents,
    search,
    searchPlace,
    selectedCategory
  );

  return (
    <>
      <div className="mx-auto sm:max-w-[1536px] sm:h-[600px] max-w-[640px] w-[390px] sm:w-[1536px]">
        
          <div
            className="absolute sm:w-[640px] md:w-[1536px] w-[388px] bg-center z-0 flex justify-between  h-[600px] "
            style={{
              backgroundImage: `url(${backgroundImage})`,
              opacity: "0.15",
            }}
          ></div>
          <Carousel />
        </div>

        <div className=" hidden sm:flex mt-[-56px]">
          <EventFilter
            search={search}
            handleSearch={handleSearch}
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
            searchPlace={searchPlace}
            handleSearchPlace={handleSearchPlace}
          />
        </div>
        <div className="p-4 space-y-5 pt-10">
          {isLoading ? (
            <div
              className={`skeleton-wrapper ${
                isLoading ? "" : "skeleton-hidden"
              }`}
            >
              <EventCardSkeleton />
            </div>
          ) : (
            <div
              className={`skeleton-wrapper ${
                isLoading ? "skeleton-hidden" : ""
              }`}
            >
              <div
                className={`event-row ${isLoading ? "" : "event-row-visible"}`}
              >
                <EventRow
                  title="Popular Events"
                  events={popularFilteredEvents}
                />
              </div>
            </div>
          )}
          <div className="pb-[40px] pt-[40px]"> <EventLink /></div>

          {isLoading ? (
            <div
              className={`skeleton-wrapper ${
                isLoading ? "" : "skeleton-hidden"
              }`}
            >
              <EventCardSkeleton />
            </div>
          ) : (
            <div
              className={`skeleton-wrapper ${
                isLoading ? "skeleton-hidden" : ""
              }`}
            >
              <div
                className={`event-row ${isLoading ? "" : "event-row-visible"}`}
              >
                <EventRow
                  title="Upcoming Events"
                  events={upcomingFilteredEvents}
                />
              </div>
            </div>
          )}
          {isLoading ? (
            <div
              className={`skeleton-wrapper ${
                isLoading ? "" : "skeleton-hidden"
              }`}
            >
              <EventCardSkeleton />
            </div>
          ) : (
            <div
              className={`skeleton-wrapper ${
                isLoading ? "skeleton-hidden" : ""
              }`}
            >
              <div
                className={`event-row ${isLoading ? "" : "event-row-visible"}`}
              >
                <EventRow title="Weekend" events={weekendFilteredEvents} />
              </div>
            </div>
          )}
          <div className="pb-[40px] pt-[40px]">
            {" "}
            <AreYouAnEventOrganizer />
          </div>

          {isLoading ? (
            <div
              className={`skeleton-wrapper ${
                isLoading ? "" : "skeleton-hidden"
              }`}
            >
              <EventCardSkeleton />
            </div>
          ) : (
            <div
              className={`skeleton-wrapper ${
                isLoading ? "skeleton-hidden" : ""
              }`}
            >
              <div
                className={`event-row ${isLoading ? "" : "event-row-visible"}`}
              >
                <EventRow
                  title="Featured Events"
                  events={featuredFilteredEvents}
                />
              </div>
            </div>
          )}
          {isLoading ? (
            <div
              className={`skeleton-wrapper ${
                isLoading ? "" : "skeleton-hidden"
              }`}
            >
              <EventCardSkeleton />
            </div>
          ) : (
            <div
              className={`skeleton-wrapper ${
                isLoading ? "skeleton-hidden" : ""
              }`}
            >
              <div
                className={`event-row ${isLoading ? "" : "event-row-visible"}`}
              >
                <EventRow title="Music Events" events={musicFilteredEvents} />
              </div>
            </div>
          )}
        </div>     
    </>
  );
}

export default Dashboard;
