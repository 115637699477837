
import Signup from "../pages/Authentication/Signup";
import Login from "../pages/Authentication/Login";
import AllEvents from "../pages/Events/AllEvents";
import EventDetails from "../pages/Events/EventDetails";
import EventCheckoutSingle from "../pages/Events/EventCheckoutSingle";
import EventCheckoutMultiple from "../pages/Events/EventCheckoutMultiple";
import Carousel from "../pages/Events/Carousel";
// import { Carousel } from "flowbite";
import Vendors from "../pages/Vendors/Vendors";
import EventOrganizer from "../pages/EventOrganizer/EventOrganizer";

const authRoutes = [
  { path: "/signup", component: Signup },
  { path: "/login", component: Login },  
];

const userRoutes = [
  { path: "/vendors", component: Vendors },
  { path: "/event-organizer", component: EventOrganizer },
];

const userNonAuthRoutes = [
  { path: "/events", component: AllEvents },
  { path: "/events/:id", component: EventDetails },
  { path: "/checkout/single", component: EventCheckoutSingle },
  { path: "/checkout/multiple", component: EventCheckoutMultiple },
  { path: "/carousel", component: Carousel },
];

export { authRoutes, userRoutes, userNonAuthRoutes };
