import React from 'react'
import event_organizer from '../../images/event-organizer.png'

function AreYouAnEventOrganizer() {
    return (
        <>
            <div className='event-organizer-opacity container relative  sm:items-stretch sm:justify-start mx-auto sm:max-w-[1400px] sm:h-[280px] h-[250px]'>
                <div className='flex flex-col sm:flex-row items-center sm:justify-center sm:p-8'>
                    <div className='hidden sm:flex flex-shrink-0 sm:p-6'>
                        <img src={event_organizer} alt='event-organizer'/>
                    </div>
                    <div className='flex flex-col space-y-4 items-center justify-center mt-2'>
                        <p className='text-primaryBlue900 text-[18px] sm:text-[28px] font-bold leading-tight'>Are you an Event Organizer?</p>
                        <p className='text-white font-roboto text-[14px] sm:text-base font-normal leading-6'>GiG provides event organizers with a simple way of selling <br/> tickets, engaging attendees and getting insights from any <br/> kind of event such as Meet-Ups, Expos, Corporate Events, <br/> Concerts, Activations or Fashion Events</p>
                        <button className='w-64 sm:w-72 sm:h-14 bg-customOrange text-white font-bold py-2 px-6 text-base rounded-full hover:bg-orangeBrown'>Explore More</button>
                    </div>
                </div>

            </div>

        </>
    )
}

export default AreYouAnEventOrganizer
