import React from 'react'
import qr_code from '../../images/playstore-qr.jpeg'
import playstore from '../../images/plasytore-image.png'
import applestore from '../../images/applestore-image.png'
import android from '../../images/android-phone.png'
import iPhone from '../../images/iPhone.png'
import Strings from '../../util/Strings'

function EventLink() {
  return (
    <>
      <div className='event-gradient container relative sm:items-stretch sm:justify-start mx-auto max-w-5xl h-[100px] '>
        <div className='flex flex-row items-center justify-center space-x-6'>
          <div className='flex flex-shrink-0 sm:p-2.5'>
            <img className='hidden sm:flex h-20' src={qr_code} alt='playstore-qr-code' />
          </div>
          <div className='flex flex-col'>
            <p className='text-white font-roboto text-[11px] sm:text-base font-normal leading-6'>{Strings.APP_DOWNLOAD_DESCRIPTION}</p>
            <div className='flex flex-row p-1 space-x-6'>
              <a href={Strings.PLAYSTORE_LINK}>
                <img src={playstore} alt='Gig GooglePlay' />
              </a>
              <a href={Strings.APPSTORE_LINK}>
                <img src={applestore} alt='Gig AppleStore' />
              </a>
            </div>
          </div>
          <div className='hidden sm:flex sm:flex-row space-x-4 p-2'>
              <img src={android} alt="Android Phone"/>
              <img src={iPhone} alt="iPhone"/>
          </div>

        </div>
      </div>
    </>
  )
}

export default EventLink
