import { faCalendarDays, faClock } from "@fortawesome/free-regular-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import EventTicketSkeleton from "./EventTicketSkeleton";
import Colors from "../../util/Colors";

const ticketColours = [Colors.pale_blue, Colors.lavendar_blue, Colors.light_peach, Colors.pastel_green, Colors.pale_yellow]

const EventDetailsSkeleton = () => (
    
  <div className="container relative  mx-auto max-w-7xl mt-10 h-3/4">
    <div className="h-auto flex flex-row  space-x-6">
      <div className="p-5 flex flex-col w-1/2 space-y-2">
        <div>
          <div className="p-1">
            <div className="bg-gray-300 animate-pulse w-3/4 h-6 rounded-md"></div>
          </div>
        </div>
        <div>
          <div className="p-1">
            <div className="flex flex-row gap-4">
              <span>
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  size="lg"
                  className="text-customGrey"
                />
              </span>
              <span className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></span>
            </div>
          </div>
          <div className="p-1">
            <div className="flex flex-row gap-3.5">
              <span>
                <FontAwesomeIcon
                  icon={faClock}
                  size="lg"
                  className="text-customGrey"
                />
              </span>
              <span className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></span>
            </div>
            <div className="p-1 mt-1">
              <div className="flex flex-row gap-4">
                <span>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    size="lg"
                    className="text-customGrey"
                  />
                </span>
                <span className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-gray-300 animate-pulse w-[360px] h-96 rounded-lg"></div>
        </div>
      </div>
      <div className="p-5 flex flex-col space-y-4">
        <div className="space-y-2 w-3/4">
          <div className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></div>
          <div className="bg-gray-300 animate-pulse w-96 h-10 rounded-md"></div>
        </div>
        <div>
          <div className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></div>
          <div className="flex flex-row flex-wrap">
            {[...Array(5)].map((_, index) => (
              <div key={index} className="w-1/3 mb-4">
               <EventTicketSkeleton color={ticketColours[index % ticketColours.length]} />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          {/* <div className="bg-gray-300 animate-pulse w-full h-12 rounded-lg"></div> */}
          <button
            disabled
            className="bg-customOrange text-white hover:text-white font-bold py-2 px-6 rounded-lg text-base"
          >
            Continue To Check Out
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default EventDetailsSkeleton;
