import CardSkeleton from "./CardSkeleton";
function EventCardSkeleton() {
  return (
    <div className="flex flex-col mb-0 container relative mx-auto max-w-7xl mt-3 ">
      <div className="flex flex-row overflow-x-auto max-w-full hide-scrollbar">
       <CardSkeleton/>
       <CardSkeleton/>
       <CardSkeleton/>
       <CardSkeleton/>
       <CardSkeleton/>
       <CardSkeleton/>
      </div>
    </div>
  );
}

export default EventCardSkeleton;
