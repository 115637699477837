
export const handleInputChange = (children, setChildren, ticketIndex, attendeeIndex, type, value) => {
    const updatedChildren = [...children];

    if (!updatedChildren[ticketIndex]) {
        updatedChildren[ticketIndex] = [];
    }

    if (!updatedChildren[ticketIndex][attendeeIndex]) {
        updatedChildren[ticketIndex][attendeeIndex] = {
            firstName: '',
            lastName: '',
        };
    }

    const updatedAttendee = {
        ...updatedChildren[ticketIndex][attendeeIndex],
        [type]: value,
    };

    updatedChildren[ticketIndex][attendeeIndex] = updatedAttendee;

    const { firstName, lastName } = updatedAttendee;

    if (firstName && lastName) {
        updatedChildren[ticketIndex][attendeeIndex] = `${firstName} ${lastName}`;
    }

    setChildren(updatedChildren);
};
