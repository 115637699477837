import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../images/gig_logo 1.png";
import { useAuth } from "../../hooks/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
  const { activeUser, logOutUser } = useAuth();
  const [open, setOpen] = useState(false);

  const handleLogOut = (e) => {
    e.preventDefault();
    logOutUser();
  };   

  return (
    <>
      <nav className="bg-white">
        <div className="mx-auto max-w-[1536px] h-[60px] px-2 sm:px-6 border border-gray-200 shadow-lg shadow-primaryBlack ">
          <div className="relative flex h-16 items-center ">
            <div className="absolute inset-y-0 right-0 flex items-center sm:hidden flex-col">
              {/* <!-- Mobile menu button--> */}              
              <div  className="block md:hidden mt-4" onClick={() => setOpen((prev) => !prev)}>
                {!open ? (
                  <FontAwesomeIcon icon={faBars} size="2xl" />
                ) : (
                  <FontAwesomeIcon icon={faX} size="2xl" />
                )}
              </div>

             
            
             
            </div>
            <div className="flex flex-1 justify-start sm:items-stretch sm:justify-start ">
              <div className="flex flex-shrink-0 items-center">
                <NavLink to="/">
                  <img
                    className="h-12 sm:pl-24 w-auto pl-1"
                    src={logo}
                    alt="Gig Logo"
                  />
                </NavLink>
              </div>
              <div className="sm:ml-6 sm:block flex-grow hidden ">
                <div className="flex justify-start ">
                  {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
                  <ul className="flex space-x-10 pl-32 ">
                    <li
                      className="text-deepBlue rounded-md px-3 py-2 text-base font-medium"
                      aria-current="page"
                    >
                      <NavLink to="/events"> Explore Events</NavLink>
                    </li>
                    {/* <li className="text-deepBlue  rounded-md px-3 py-2 text-base font-medium">
                      <NavLink to="/vendors">Vendors</NavLink>
                    </li> */}
                    {/* <li className="text-deepBlue  rounded-md px-3 py-2 text-base font-medium">
                      <NavLink to="/pricing">Pricing</NavLink>
                    </li> */}
                    {/* <li className="text-deepBlue  rounded-md px-3 py-2 text-base font-medium"><NavLink to = '/event-organizer'>Are you an Event Organizer?</NavLink></li>                   */}
                  </ul>
                  {/* <div className="space-x-10"></div> */}
                </div>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 sm:flex sm:flex-row md:mr-24 sm:static sm:inset-auto sm:ml-6 sm:pr-0 space-x-4 hidden">
              <button className="bg-customBlue text-white hover:bg-customOrange hover:text-white  py-2 px-4 rounded-lg">
                Create Events
              </button>
              {!activeUser && !activeUser?.user ? (
                <button className="border border-customBlue text-customBlue hover:bg-customOrange hover:border-transparent hover:text-white font-bold py-2 px-4 rounded-lg">
                  <NavLink to="/login">Login</NavLink>
                </button>
              ) : (
                <button
                  className="border border-customBlue text-customBlue hover:bg-customOrange hover:border-transparent hover:text-white font-bold py-2 px-4 rounded-lg"
                  onClick={handleLogOut}
                >
                  Logout
                </button>
              )}
            </div>
          </div>
        </div>
      </nav>
      {open && (
        <div className="flex flex-col basis-full items-center bg-[#f5f5f5] rounded-md">
          <ul className="">
                    <li
                      className="text-deepBlue rounded-md px-3 py-2 text-base font-medium hover:underline"
                      aria-current="page"                     
                    >
                      <NavLink to="/events"> Explore Events</NavLink>
                    </li>
                    <li
                      className="text-deepBlue  rounded-md px-3 py-2 text-base font-medium"
                      aria-current="page"
                    >
                      <NavLink to="/login"> Login </NavLink>
                    </li>
                    {/* <li className="text-deepBlue  rounded-md px-3 py-2 text-base font-medium">
                      <NavLink to="/vendors">Vendors</NavLink>
                    </li> */}
                    {/* <li className="text-deepBlue  rounded-md px-3 py-2 text-base font-medium">
                      <NavLink to="/pricing">Pricing</NavLink>
                    </li> */}
                    {/* <li className="text-deepBlue  rounded-md px-3 py-2 text-base font-medium"><NavLink to = '/event-organizer'>Are you an Event Organizer?</NavLink></li>                   */}
                  </ul>
        </div>
      ) }
    </>
  );
}

export default Navbar;
