import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import Colors from '../../util/Colors';
import { truncate } from '../../util/Util';

const ticketColours = [Colors.pale_blue, Colors.lavendar_blue, Colors.light_peach, Colors.pastel_green, Colors.pale_yellow]

function EventTicket({ ticket, index, handleTicketChange }) {

    const [ticketCount, setTicketCount] = useState(0);  
    const [increased, setIncreased] = useState(false); 

    const incrementTicket = () => {
        setTicketCount(ticketCount + 1);
        handleTicketChange(ticket.tickettype, ticketCount + 1, ticket.ticket_amount, ticket.ticketeventid, ticket.tickettypeid, ticket.id );
      };
    
      const decrementTicket = () => {
        if (ticketCount > 0) {
          setTicketCount(ticketCount - 1);
          handleTicketChange(ticket.tickettype, ticketCount - 1, ticket.ticket_amount, ticket.ticketeventid, ticket.tickettypeid, ticket.id);
        }
        setIncreased(false); 
      };
    
    const ticketColor = ticketColours[index % ticketColours.length];    

    
    return (
        <>

            <div className='p-2 w-[160px] h-auto  max-w-xs rounded-lg mt-2 flex flex-col items-start justify-center space-y-0.5' style={{ backgroundColor: ticketColor }} >
                <h4 className='font-roboto text-md font-semibold leading-6 text-primaryBlue ml-1'>{truncate(ticket.tickettype, 14)}</h4>
                <h4 className='font-roboto text-md font-semibold leading-6 text-primaryBlue ml-1'>KES {ticket.ticket_amount.toLocaleString()}</h4>
                <div className='flex flex-row space-x-2 ml-1'>
                    <FontAwesomeIcon icon={faCircleInfo} className='text-primaryBlack mt-1 hover:text-customGrey' />
                    <p className='underline text-primaryBlack hover:text-customBlue'>See more info</p>
                </div>
                <hr className="bg-primaryBlack h-0.5 w-[142px]" />
                <div className='flex flex-row space-x-8 ml-4 h-[]'>
                    <button onClick={() => decrementTicket()}><FontAwesomeIcon icon={faMinus} className='bg-primaryBlack rounded' size='sm' inverse /></button>
                    <span>{ticketCount}</span>                  
                    <button onClick={incrementTicket}><FontAwesomeIcon icon={faPlus} className='bg-primaryBlack rounded' size='sm' inverse /></button>
                </div>
               
                
                {/* <p>Total Price: ${ticketCount * TICKET_PRICE}</p> */}
            </div>

        </>
    )
}

export default EventTicket
