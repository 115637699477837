import React from "react";
import "../Dashboard/dashboard.css";
import EventFilter from "./EventFilter";
import EventCard from "./EventCard";
import { useEvents } from "../../hooks/useEvents";
import { useFilterHooks } from "../../hooks/filterHooks";
import { useFilteredEvents } from "../../util/Filter";
import CardSkeleton from "./CardSkeleton";

function AllEvents() {
  const { allEvents, isLoading } = useEvents();
  const {
    search,
    searchPlace,
    selectedCategory,
    handleSearch,
    handleSearchPlace,
    handleCategoryChange,
  } = useFilterHooks();

  const allFilteredEvents = useFilteredEvents(
    allEvents,
    search,
    searchPlace,
    selectedCategory
  );

  const eventList = allFilteredEvents.map((event) => (
    <EventCard key={event.id} event={event} />
  
  ));

  return (
    <>
      <div className="container max-w-[1536px] mx-auto relative justify-between ">
        <div className=" pt-12">
          <EventFilter
            search={search}
            handleSearch={handleSearch}
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
            searchPlace={searchPlace}
            handleSearchPlace={handleSearchPlace}
          />
        </div>

        <div className="flex flex-wrap container relative mx-auto max-w-9xl md:py-16 pl-24">
          {isLoading ? (
            Array.from({ length: 25 }, (_, index) => (
              <div className={`skeleton-wrapper`} key={index}>
                <CardSkeleton />
              </div>
            ))
          ) : (
            <div
              className={`event-list ${
                isLoading ? "event-list-hidden" : "event-list-visible"
              } flex flex-wrap`}
            >
              {eventList}
            </div>
          )}
        </div>
      


      </div>
    </>
  );
}

export default AllEvents;
