import { useState, useEffect } from "react";


export const useEvents = () => {
  const [allEvents, setAllEvents] = useState([])
  const [popularEvents, setPopularEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [musicEvents, setMusicEvents] = useState([])
  const [weekendEvents, setWeekendEvents] = useState([])
  const [featuredEvents, setFeaturedEvents] = useState([])
  const [carouselEvents, setCarouselEvents] = useState([])

  const [event, setEvent] = useState('')
  const [isLoading, setIsLoading] = useState(false)


  const fetchEvents = (url, setter) => {
    setIsLoading(true);
    fetch(url)
      .then(res => res.json())
      .then((events) => {
        setter(events.data) 
       setIsLoading(false)    
      })
      
      .catch(error => {
        // Handle fetch errors
        console.error('Error fetching events:', error);
      })
      .finally(() => {
        setIsLoading(false); 
      })
  };

  useEffect(() => {
    fetchEvents('https://client.gig.co.ke/api/events/all', setAllEvents);
    fetchEvents('https://client.gig.co.ke/api/events/upcoming', setUpcomingEvents);
    fetchEvents('https://client.gig.co.ke/api/events/popular', setPopularEvents);
    fetchEvents('https://client.gig.co.ke/api/events/music', setMusicEvents);
    fetchEvents('https://client.gig.co.ke/api/events/weekend', setWeekendEvents);
    fetchEvents('https://client.gig.co.ke/api/events/featured', setFeaturedEvents);
    fetchEvents('https://client.gig.co.ke/api/events/featured', setCarouselEvents);
  }, []);

  const loadEvent = (id) => {
    
    fetch(`https://client.gig.co.ke/api/event/single`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ eventid: id }),
    })
    .then((res) => res.json())
    .then((response) => {            
      if (response && response.data) {
        setEvent(response.data);               
      } else {
        console.error('No data found in the API response.');       
      }
    })
    .catch((error) => {
      console.error('Error fetching event data:', error);      
    });
  }




  return {
    allEvents,
    popularEvents,
    upcomingEvents,
    musicEvents,
    weekendEvents,
    featuredEvents,
    loadEvent,
    event,
    carouselEvents,
    isLoading
  }
}


