import React from "react";
import { Navigate } from "react-router-dom";

function AuthMiddleware({
                component: Component,
                isAuthProtected,
                isDoneChecking,
                isLoggedIn,
                ...rest
                }) {
                    if (isAuthProtected && isDoneChecking && !isLoggedIn) {
                        return (
                            <Navigate
                                to={{pathname: "/login"}}
                            />
                        )
                    }
        
  return <Component {...rest} />;
}

export default AuthMiddleware;
