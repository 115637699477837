import React from "react";
import logo from "../../images/gig_logo_white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="bg-darkNavyBlue max-w-[1536px] h-[300px] sm:h-[360px] max-h-[360px] mx-auto container relative justify-between mt-10 rounded-lg">
        <div className=" mx-auto w-full max-w-screen-xl p-4">
          <div className="md:flex md:justify-between">
            <div className="hidden sm:flex mb-6 md:mb-0">
              <a
                href="https://gig.co.ke/"
                className="flex flex-col items-center"
              >
                <img src={logo} className="h-24 me-3" alt="FlowBite Logo" />
                <span className="font-roboto text-base font-normal leading-relaxed text-white p-2 ">
                  GiG is a self-service ticketing platform for <br /> live
                  experiences that allows anyone to <br /> create, share, find
                  and attend events that <br /> fuel their passions and enrich
                  their lives.
                </span>
              </a>
            </div>
            <div className="grid grid-cols-4 space-x-4 sm:space-x-8 gap-8 sm:gap-6 sm:grid-cols-4">
              <div>
                <h2 className="sm:mb-6 mb-2 font-semibold text-customOrange  dark:text-white font-roboto text-[14px] sm:text-lg leading-relaxed">
                  Products
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium ">
                  <li className="sm:mb-4">
                    <a
                      href="#1"
                      className="hover:underline font-roboto leading-relaxed text-[10px] sm:text-sm text-whiteFooter hover:text-customOrange"
                    >
                      Explore Events
                    </a>
                  </li>
                  <li className="sm:mb-4">
                    <a
                      href="#2"
                      className="hover:underline font-roboto  leading-relaxed text-[10px] sm:text-sm text-whiteFooter hover:text-customOrange"
                    >
                      Community
                    </a>
                  </li>
                  <li className="sm:mb-4">
                    <a
                      href="#3"
                      className="hover:underline font-roboto  leading-relaxed text-[10px] sm:text-sm text-whiteFooter hover:text-customOrange"
                    >
                      Organizer?
                    </a>
                  </li>
                  <li className="sm:mb-4">
                   
                      <NavLink
                        to="/pricing"
                        className="hover:underline font-roboto leading-relaxed text-[10px] sm:text-sm text-whiteFooter hover:text-customOrange"
                      >
                        Pricing
                      </NavLink>
                    
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="sm:mb-6 mb-2 font-semibold text-customOrange  dark:text-white font-roboto text-[14px] sm:text-lg leading-relaxed">
                  GiG
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="sm:mb-4">
                    <a
                      href="#1"
                      className="hover:underline font-roboto text-[10px] sm:text-sm text-whiteFooter leading-relaxed hover:text-customOrange"
                    >
                      About Us
                    </a>
                  </li>
                  <li className="sm:mb-4">
                    <a
                      href="#1"
                      className="hover:underline font-roboto  text-[10px] sm:text-sm text-whiteFooter leading-relaxed hover:text-customOrange"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li className="sm:mb-4">
                    <a
                      href="#1"
                      className="hover:underline font-roboto  text-[10px] sm:text-sm text-whiteFooter leading-relaxed hover:text-customOrange"
                    >
                      Blog
                    </a>
                  </li>
                  <li className="sm:mb-4">
                    <a
                      href="#1"
                      className="hover:underline font-roboto  text-[10px] sm:text-sm text-whiteFooter leading-relaxed hover:text-customOrange"
                    >
                      FAQs
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="sm:mb-6 mb-2 font-semibold text-customOrange  dark:text-white font-roboto text-lg leading-relaxed">
                  Legal
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="sm:mb-4">
                    <a
                      href="#1"
                      className="hover:underline font-roboto  text-[10px] sm:text-sm text-whiteFooter leading-relaxed hover:text-customOrange"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="#2"
                      className="hover:underline font-roboto text-[10px] sm:text-sm text-whiteFooter leading-relaxed hover:text-customOrange"
                    >
                      Terms &amp; Conditions
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="sm:mb-6 mb-2 font-semibold text-customOrange  dark:text-white font-roboto text-[14px] sm:text-lg leading-relaxed">
                  Social Media
                </h2>
                <div className="grid grid-cols-2 sm:flex mt-4 justify-start sm:mt-0 gap-3">
                  <a
                    href="#1"
                    className="text-facebook hover:text-gray-900 dark:hover:text-white"
                  >
                    <FontAwesomeIcon icon={faFacebook} size="xl" />
                    <span className="sr-only">Facebook page</span>
                  </a>
                  <a
                    href="#3"
                    className="text-black hover:text-gray-900 dark:hover:text-white sm:ms-5"
                  >
                    <FontAwesomeIcon icon={faXTwitter} size="xl" />
                    <span className="sr-only">Twitter page</span>
                  </a>
                  <a
                    href="#4"
                    className="text-instagram hover:text-gray-900 dark:hover:text-white sm:ms-5"
                  >
                    <FontAwesomeIcon icon={faInstagram} size="xl" />
                    <span className="sr-only">Instagram Page</span>
                  </a>
                  <a
                    href="#2"
                    className="text-linkedIn hover:text-gray-900 dark:hover:text-white sm:ms-5"
                  >
                    <FontAwesomeIcon icon={faLinkedin} size="xl" />
                    <span className="sr-only">Discord community</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-10 border-gray-200 sm:mx-auto" />
          <div className="sm:flex sm:items-center sm:justify-center ">
            <span className="text-sm text-customWhite  font-roboto sm:text-center dark:text-gray-400">
              © 2023{" "}
              <a href="https://gig.co.ke/" className="hover:underline">
                GiG™
              </a>
              . All Rights Reserved.
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
