// import { isLocalEnvironment, isStagingEnvironment } from "./Util";

const URL = "https://client.gig.co.ke"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    SERVER_HOST: URL,
    API_URL: '/api/',
    LOGIN: 'user/token',
    REGISTER: 'user/register',
    LOGOUT: 'logout',
    POPULAR: `/events/popular`,
    EVENT_WITH_ID: '/event/single',
    ALL_EVENTS: '/events/all'
    
}