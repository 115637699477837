
export const useFilteredEvents = (events, search, searchPlace, selectedCategory) => {
    return events.filter(e => {
      const categoryMatch = !selectedCategory || e.eventcategory.toLowerCase().includes(selectedCategory.toLowerCase());
      const nameMatch = !search || e.eventname.toLowerCase().includes(search.toLowerCase());
      const locationMatch = !searchPlace || e.eventlocationname.toLowerCase().includes(searchPlace.toLowerCase());
  
      if (selectedCategory && search && searchPlace) {
        return categoryMatch && nameMatch && locationMatch;
      } else if (selectedCategory && search) {
        return categoryMatch && nameMatch;
      } else if (selectedCategory && searchPlace) {
        return categoryMatch && locationMatch;
      } else if (search && searchPlace) {
        return nameMatch && locationMatch;
      } else if (selectedCategory) {
        return categoryMatch;
      } else if (search) {
        return nameMatch;
      } else if (searchPlace) {
        return locationMatch;
      }
  
      return true;
    });
  };