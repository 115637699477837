import React, { useRef, useState } from 'react'
import EventCard from './EventCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';


function EventRow({ title, events, titleStyle }) {   

    const eventListRef = useRef(null);
    const [scrolling, setScrolling] = useState(false);

    const scrollRight = () => {
        if (eventListRef.current) {
            eventListRef.current.scrollBy({
                left: 200, // Adjust as needed
                behavior: 'smooth'
            });
            setScrolling(true);
        }
    };

    const scrollLeft = () => {
        if (eventListRef.current) {
            eventListRef.current.scrollBy({
                left: -200, // Adjust as needed
                behavior: 'smooth'
            });
            setScrolling(true);
        }
    };

    const eventList = events.map((event) => (
        <EventCard key={event.id} event={event}/>
    ))

    return (
        <>
            <div className='flex flex-col mb-0 container relative mx-auto sm:max-w-7xl mt-3 max-w-[380px]' >
                <div>
                    <h2 className='mb-2 text-primaryBlue text-[19px] font-bold' style={titleStyle}>{title}</h2>                  
                </div>
                <div className='flex flex-row overflow-x-auto sm:max-w-[1536px] hide-scrollbar' ref={eventListRef} >
                {events.length > 4 && <FontAwesomeIcon icon={faArrowLeft} onClick={scrollLeft} className='text-primaryBlue cursor-pointer mt-2 absolute left-[-30px] top-64 hidden sm:flex' />}
                    {eventList}                    
                    {events.length > 4 && <FontAwesomeIcon icon={faArrowRight} onClick={scrollRight} className='text-primaryBlue cursor-pointer mt-2 absolute right-[-60px] top-64 hidden sm:flex' />}
                </div>
            </div>
        </>
    )
}

export default EventRow
