export const calculateTotalAmount = (ticketData) => {
    let totalAmount = 0;
    Object.values(ticketData).forEach((ticketInfo) => {
      totalAmount += ticketInfo.totalPrice || 0;
    });
    return totalAmount;
  };

  export const calculateTotalCount = (ticketData) => {
    let count = 0;
    Object.values(ticketData).forEach((ticketInfo) => {
      count += ticketInfo.ticketCount || 0;
    });
    return count;
  };
  
  export const updateTicketData = (type, count, amount, eventID, ticketID, ticketTypeID, ticketData, setTicketData) => {
    const updatedData = { ...ticketData };
    if (count === 0 && updatedData[type]) {
      delete updatedData[type]; // Remove the ticket type if count is zero
    } else {
      updatedData[type] = {
        ticketType: type,
        ticketCount: count,
        ticketAmount: amount,
        totalPrice: count * amount,
        eventID: eventID,
        ticketTypeID: ticketTypeID,
        ticketID: ticketID   
      };
    }
    setTicketData(updatedData);
  };

  export const getTotalCountByType = (ticketData, type) => {
    let totalCount = 0;
    if (ticketData[type]) {
      totalCount = ticketData[type].ticketCount;
    }
    return totalCount;
  }; 



  export const getTicketTypeCountArray = (ticketData, eventName) => { 

    return Object.keys(ticketData).map((ticketType) => ({           
      eventName,
      ticketType,
      totalCount: getTotalCountByType(ticketData, ticketType),
      ticketPrice: ticketData[ticketType].ticketAmount,     
      totalPrice: calculateTotalAmount(ticketData),
      eventID: ticketData[ticketType].eventID,
      ticketTypeID: ticketData[ticketType].ticketTypeID,
      ticketID: ticketData[ticketType].ticketID,     

    }));
  };


  

