import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function EventTicketSkeleton({color}) {
  return (
    <>
      <div
        className="p-2 w-60 h-auto flex-shrink-0 max-w-xs rounded-lg mt-5 flex flex-col items-center justify-center space-y-2"
        style={{ backgroundColor: color}}
      >
        <div className="bg-gray-300 h-6 w-full rounded-md animate-pulse"></div>
        <div className="bg-gray-300 h-6 w-full rounded-md animate-pulse"></div>
        <div className="flex flex-row space-x-2">
          <div className="bg-gray-300 h-6 w-6 rounded-full animate-pulse"></div>
          <div className="bg-gray-300 h-6 w-20 rounded-md animate-pulse"></div>
        </div>
        <hr className="bg-gray-300 h-0.5 w-full" />
        <div className='flex flex-row space-x-8'>
                    <button ><FontAwesomeIcon icon={faMinus} className='bg-primaryBlack rounded' size='lg' inverse /></button>
                    <div className='bg-gray-300 h-6 w-10 rounded-lg'></div>
                    <button><FontAwesomeIcon icon={faPlus} className='bg-primaryBlack rounded' size='lg' inverse /></button>
                </div>
      </div>
    </>
  );
}

export default EventTicketSkeleton;
