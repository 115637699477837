import React, { useState } from 'react'
import { truncate } from '../../util/Util';

function EventSummary({ event }) {

    const [showFullText, setShowFullText] = useState(false);
    const maxLength = 200; // Maximum characters to show initially

    const toggleShowFullText = () => {
        setShowFullText(!showFullText);
    };

    if (!event || typeof event.eventdescription !== 'string') {
        return null; // or display a default message if event or event.eventdescription is undefined or not a string
    }

    const eventSummary = event.eventdescription;
    
    return (
        <>
            <div className=''>
                {showFullText || eventSummary.length <= maxLength ? (
                    <>
                        <p className='font-roboto text-base font-normal leading-6 flex-grow'>
                            {truncate(eventSummary, 373)}
                            {eventSummary.length > maxLength && (
                                <button onClick={toggleShowFullText} className='text-blue-500 underline ml-2'>
                                    Read Less
                                </button>
                            )}
                        </p>


                    </>
                ) : (
                    <>

                        <p className='font-roboto text-base font-normal leading-6'>
                            {`${eventSummary.substring(0, maxLength)}...`}
                            
                            <button onClick={toggleShowFullText} className='text-blue-500 underline ml-2'>
                                Read More
                            </button>
                        </p>

                    </>
                )}
            </div>
        </>
    )
}

export default EventSummary
