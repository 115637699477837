import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function CardSkeleton() {
  return (
    <div className="w-72 h-[420px] flex-shrink-0 max-w-sm bg-white border border-gray-200 rounded-lg shadow-md dark:border-gray-200 m-5 mb-30 transition duration-500">
      <div className="border border-gray-200 dark:border-gray-20 animate-pulse">
        <div className="rounded-l min-h-[300px] min-w-[284px] max-w-md max-h-72 w-[284px] bg-gray-200 animate-pulse" />
      </div>

      <div className="p-2 flex flex-col space-y-1">
        <div className="float-left text-base text-gray-900 w-85 ">
          <div className="pl-1">
            <div className="h-6 bg-gray-200 mb-2 animate-pulse rounded-lg" />
          </div>
        </div>
        <div className="">
          <div className="flex flex-row space-x-3 pl-2">
            <span  className="animate-pulse">
              <FontAwesomeIcon
                icon={faCalendarDays}
                className="text-gray-400"
              />
            </span>          
            <span className="h-4 p bg-gray-200 w-16 animate-pulse rounded-lg"></span>  
            <div />
         
          </div>
        </div>
        <div className="">
          <div className="flex flex-row space-x-3 pl-2">
            <div className="h-4 w-4 bg-gray-200 rounded-full animate-pulse" />
            <div className="h-4 bg-gray-200 w-16 animate-pulse rounded-lg" />
          </div>
        </div>
        <div className="">
          <div className="flex flex-row space-x-3 pl-2">
            <div className="h-4 w-4 bg-gray-200 rounded-full animate-pulse " />
            <div className="h-4 bg-gray-200 w-16 animate-pulse rounded-lg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardSkeleton;
