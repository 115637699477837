import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
import { capitalize } from '../../util/Util'
import mpesa from '../../images/mpesa.png'
import visa from '../../images/visa.png'
import Colors from '../../util/Colors'
import { handleInputChange } from '../../util/inputChange'
import { confirmInfoMultiple } from '../../util/PaymentMultiple'
// import { confirmInfo } from '../../util/Payment'


function EventCheckoutMultiple() {
    const location = useLocation()
    const [ticketTypeArray, setTicketTypeArray] = useState(location.state);
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [postalAddress, setPostalAddress] = useState('')
    const [physicalAddress, setPhysicalAddress] = useState('')
    const [promoCode, setPromoCode] = useState('')
    const [gender, setGender] = useState('');
    const [children, setChildren] = useState([]);
    const [showInput, setShowInput] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const ticketColours = [Colors.pale_blue, Colors.lavendar_blue, Colors.light_peach, Colors.pastel_green, Colors.pale_yellow]
    const ticketCount = ticketTypeArray.map((ttype) => ttype.totalCount)
    const ticketType = ticketTypeArray.map((ttype) => ttype.ticketType)
    const eventName = ticketTypeArray.map((ttype) => ttype.eventName)
    const eventID = ticketTypeArray.map((ttype) => ttype.eventID)
    const ticketID = ticketTypeArray.map((ttype) => ttype.ticketID)
    const ticketTypeID = ticketTypeArray.map((ttype) => ttype.ticketTypeID)
    const ticketPrice = ticketTypeArray.map((ttype) => ttype.ticketPrice)
    

    const handleClick = () => {
        setShowInput(!showInput);
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleInput = (ticketIndex, attendeeIndex, type, value) => {
        handleInputChange(children, setChildren, ticketIndex, attendeeIndex, type, value);
    };

    console.log(ticketTypeArray);

    const handleAddRecipient = (index) => {
        setTicketTypeArray((prevTicketTypeArray) => {
            const updatedTicketTypeArray = [...prevTicketTypeArray];
            const updatedTicket = { ...updatedTicketTypeArray[index] };
            updatedTicket.totalCount += 1;
            updatedTicket.totalPrice = updatedTicket.ticketPrice * updatedTicket.totalCount;
            updatedTicketTypeArray[index] = updatedTicket;
            return updatedTicketTypeArray;
        });
    };

    const calculateTotalPrice = () => {
        let total = 0;

        if (ticketTypeArray) {
            for (const ticket of ticketTypeArray) {
                total += ticket.ticketPrice * ticket.totalCount;
            }
        }

        return total.toLocaleString();
    };  


    const handleSubmit = (e) => {
        e.preventDefault()
        if (selectedImage && selectedImage === 'mpesa') {
            const userData = {
                eventid: eventID[0],
                eventname: eventName[0],
                ttype: ticketType,
                tid: ticketID,
                tickettypeid: ticketTypeID,
                tnumber: ticketCount,
                tprice: ticketPrice,
                tseats: null,
                tuserid: "user_1",
                tamount: calculateTotalPrice(),
                email: email,
                name: `${firstName} ${lastName}`,
                number: phoneNumber,
                company: "GiG",
                occupation: "Support",
                postaladdress: postalAddress,
                physicaladdress: physicalAddress,
                secondname: lastName,
                officephonenumber: '070000000',
                gender: gender,
                age: null,
                treferall: promoCode ? promoCode : "Gig",
                children: children,
                tinsured: false,
                pay_via: selectedImage,
                order_id: "G269208"
            }

        confirmInfoMultiple(userData);
        
        }
    }

    return (
        <>
            <div className='container mx-auto relative max-w-[1536px]'>
            <form onSubmit={handleSubmit}>
                <div className='flex flex-row pt-10 gap-32'>
                    <div className='flex flex-col space-y-2  pl-48'>
                        <div className='flex flex-col gap-3'>
                            <p className="font-roboto font-semibold text-xl leading-[150%] text-primaryBlue">Checkout</p>
                            <div className='flex flex-row space-x-2 bg-infoGrey items-center justify-center h-10 w-[600px] rounded-lg'>
                                <FontAwesomeIcon icon={faCircleInfo} className=' text-infoBlue' />
                                <p className='font-roboto text-sm font-normal leading-[150%] text-infoBlue'>We’ve reserved your tickets please complete checkout within 09:24 to secure your tickets.</p>
                            </div>
                        </div>
                        <div className='flex flex-col gap-3 p-2'>
                            <p className='font-roboto text-base font-normal leading-[150%]'>Where should we send your booking confirmation?</p>
                            <div className='grid gap-6 mb-6 md:grid-cols-2'>
                                <div className='flex flex-col '>
                                    <label htmlFor="email" className='text-primaryBlack font-roboto text-base font-medium leading-6 uppercase'>Email<span className='text-secondaryRed'>*</span></label>
                                    <input required type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className='items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg' placeholder='ricky@gmail.com' />
                                </div>
                                <div className='flex flex-col'>
                                    <label htmlFor="phone" className='text-primaryBlack font-roboto text-base font-medium leading-6'>Phone Number<span className='text-secondaryRed'>*</span></label>
                                    <input required type="tel" id="phone" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className='items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg' placeholder='+254 700 000 000' />
                                </div>
                            </div>

                        </div>
                        <div className='flex flex-col gap-2 p-2'>
                            <div className='flex flex-row gap-4'>
                                <p className="font-roboto font-medium text-base leading-[150%] text-primaryBlue">Personal Details</p>
                                <div className='flex flex-row space-x-2 bg-infoGrey items-center justify-center h-6 w-[400px] rounded-lg'>
                                    <FontAwesomeIcon icon={faCircleInfo} className=' text-burntOrange' />
                                    <p className='font-roboto text-xs font-normal leading-4 text-burntOrange'>Make sure to include your name on the Attendee Details below</p>
                                </div>
                            </div>
                            <div className='grid gap-6 mb-6 md:grid-cols-2'>
                                <div className='flex flex-col '>
                                    <label htmlFor="name" className='text-primaryBlack font-roboto text-base font-medium leading-6'>First Name<span className='text-secondaryRed'>*</span></label>
                                    <input required type="text" id="first_name" value={firstName} onChange={(e) => setFirstName(e.target.value)} className='items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg' placeholder='John' />
                                </div>
                                <div className='flex flex-col'>
                                    <label htmlFor="name" className='text-primaryBlack font-roboto text-base font-medium leading-6'>Last Name<span className='text-secondaryRed'>*</span></label>
                                    <input required type="text" id="last_name" value={lastName} onChange={(e) => setLastName(e.target.value)} className='items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg' placeholder='Doe' />
                                </div>
                                <div className='flex flex-col '>
                                    <label htmlFor="postal_address" className='text-primaryBlack font-roboto text-base font-medium leading-6'>Postal Address<span className='text-secondaryRed'>*</span></label>
                                    <input required type="text" id="postal_address" value={postalAddress} onChange={(e) => setPostalAddress(e.target.value)} className='items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg' placeholder='01000' />
                                </div>
                                <div className='flex flex-col'>
                                    <label htmlFor="address" className='text-primaryBlack font-roboto text-base font-medium leading-6'>Physical Address<span className='text-secondaryRed'>*</span></label>
                                    <input required type="text" id="physical_address" value={physicalAddress} onChange={(e) => setPhysicalAddress(e.target.value)} className='items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg' placeholder='Nairobi' />
                                </div>
                                <div className='flex flex-col'>
                                    <p className='text-primaryBlack font-roboto text-base font-medium leading-6'>Gender</p>
                                    <div className='flex flex-row gap-6'>
                                        <div
                                            onClick={() => setGender('Male')}
                                            className={gender === 'Male' ? 'items-center justify-center border rounded-lg cursor-pointer border-primaryBlack text-secondaryBlue  text-md p-2 font-roboto bg-lavendarBlue' : 'items-center justify-center border rounded-lg cursor-pointer border-primaryBlack text-primaryBlack rounded-lg cursor-pointer text-md p-2 font-roboto hover:text-secondaryBlue hover:border-secondaryBlue'}
                                        >
                                            <span>Male</span>
                                        </div>
                                        <div
                                            onClick={() => setGender('Female')}
                                            className={gender === 'Female' ? 'items-center justify-center border rounded-lg cursor-pointer border-primaryBlack text-secondaryBlue  text-md p-2 font-roboto bg-lightPastel' : 'items-center justify-center border rounded-lg cursor-pointer border-primaryBlack text-primaryBlack rounded-lg cursor-pointer text-md p-2 font-roboto hover:text-secondaryBlue hover:border-secondaryBlue'}
                                        >
                                            <span>Female</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2 p-2'>
                            <div className='flex flex-col gap-4'>
                                <div className='flex flex-col gap-4'>
                                    <p className="font-roboto font-medium text-base leading-[150%] text-primaryBlue">Attendee Details</p>

                                </div>

                                {ticketTypeArray.map((ticket, ticketIndex) => (
                                    <div key={ticket.ticketTypeID} className='flex flex-col gap-4'>
                                        <div className='flex flex-row gap-0'>
                                            <p className='flex flex-row items-center justify-center h-10 w-[300px] rounded-lg'
                                                style={{ backgroundColor: ticketColours[ticketIndex % ticketColours.length] }}>
                                                {ticket.totalCount} {ticket.ticketType} Ticket{ticket.totalCount > 1 ? 's' : ''}
                                            </p>
                                            <div className='flex flex-row space-x-2 items-center justify-center h-10 w-[180px] rounded-lg cursor-pointer' onClick={() => handleAddRecipient(ticketIndex)}>
                                                <FontAwesomeIcon icon={faPlus} className='text-primaryBlack' size='xs' />
                                                <p className='font-roboto text-base font-normal leading-7 text-primaryBlack'>Add Recipient</p>
                                            </div>
                                        </div>

                                        <div className='grid gap-6 mb-6 md:grid-cols-2'>
                                            {[...Array(ticket.totalCount * 2)].map((_, attendeeIndex) => (
                                                attendeeIndex % 2 === 0 ? (
                                                    <div key={attendeeIndex} className='flex flex-col '>
                                                        <label htmlFor={`first_name_${ticket.ticketTypeID}_${attendeeIndex / 2}`} className='text-primaryBlack font-roboto text-base font-medium leading-6'>
                                                            Attendee First Name<span className='text-secondaryRed'>*</span>
                                                        </label>
                                                        <input
                                                            required
                                                            type="text"
                                                            id={`first_name_${ticket.ticketTypeID}_${attendeeIndex / 2}`}
                                                            className='items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg'
                                                            placeholder='John'
                                                            onChange={(e) =>
                                                                handleInput(ticketIndex, attendeeIndex / 2, 'firstName', e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                ) : (
                                                    <div key={attendeeIndex} className='flex flex-col '>
                                                        <label htmlFor={`last_name_${ticket.ticketTypeID}_${(attendeeIndex - 1) / 2}`} className='text-primaryBlack font-roboto text-base font-medium leading-6'>
                                                            Attendee Last Name<span className='text-secondaryRed'>*</span>
                                                        </label>
                                                        <input
                                                            required
                                                            type="text"
                                                            id={`last_name_${ticket.ticketTypeID}_${(attendeeIndex - 1) / 2}`}
                                                            className='items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg'
                                                            placeholder='Doe'
                                                            onChange={(e) =>
                                                                handleInput(ticketIndex, (attendeeIndex - 1) / 2, 'lastName', e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col gap-5'>
                        <div className='flex flex-col'>
                            <p className="font-roboto font-medium text-base leading-[150%] text-primaryBlue">Summary</p>
                            <div className='p-4 w-[400px] h-auto flex-shrink-0 rounded-lg mt-5 flex flex-col space-y-4 bg-infoGrey'>
                                <h4 className='font-roboto text-md font-semibold leading-6 text-primaryBlue'>{capitalize(eventName)}</h4>
                                <h4 className='font-roboto text-sm font-semibold leading-6 text-secondaryRed underline cursor-pointer' onClick={() => handleClick()}>Add Promo Code?</h4>
                                {showInput && (
                                    <input
                                        type="text"
                                        id="promo_code"
                                        className="items-center justify-center border border-primaryBlack text-primaryBlack text-sm p-2.5 font-roboto rounded-lg"
                                        placeholder="PROMO CODE"
                                        value={promoCode} onChange={(e) => setPromoCode(e.target.value)}
                                    />
                                )}
                                {/**
                                 * code that updates card with count and ticket type details
                                 */}
                                 
                                {ticketTypeArray ? ticketTypeArray.map((ticket, index) => (
                                    <div key={index} className='flex flex-row'>
                                        <p className='text-secondaryBlack font-roboto text-sm font-normal leading-[150%] flex-grow'>
                                            {ticket.totalCount} x {ticket.ticketType}
                                        </p>
                                        <p className='text-secondaryBlack font-roboto text-sm font-normal leading-[150%]'>
                                            KES {(ticket.ticketPrice * ticket.totalCount).toLocaleString()}
                                        </p>
                                    </div>
                                )) : null}

                                <hr className="bg-primaryBlack h-0.5 w-[360px] mx-auto" />
                                <p className='text-primaryBlue font-roboto text-base font-semibold leading-[150%] text-right'>Total: KES {calculateTotalPrice().toLocaleString()}</p>

                            </div>
                        </div>

                        <div className='flex flex-col gap-4'>
                            <p className="font-roboto font-medium text-base leading-[150%] text-primaryBlue">Payment Information</p>
                            <div className='flex flex-row gap-8'>
                                <img src={mpesa} alt='mpesa' onClick={() => handleImageClick('mpesa')} className={selectedImage === 'mpesa' ? 'border-2 border-black rounded-lg' : 'rounded-lg'} />
                                <img src={visa} alt='visa' onClick={() => handleImageClick('visa')} className={selectedImage === 'visa' ? 'border-2 border-black rounded-lg' : 'rounded-lg'} />
                            </div>
                        </div>

                        <button className="bg-customOrange text-white hover:text-white font-bold py-2 px-6 rounded-lg text-base"
                            type="submit"
                            disabled={!selectedImage} // Disable button if no image is selected
                        >
                            Complete Payment
                        </button>

                    </div>

                </div>
            </form>
            </div>
         
        </>
    )
}

export default EventCheckoutMultiple
