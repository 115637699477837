import toast from "react-hot-toast";
import Swal from "sweetalert2"
import { postConfirmPayment } from "./ConfirmPayment";

export const confirmInfoMultiple = (data) => {
    const title = 'text-center font-roboto font-medium text-base md:text-2xl lg:text-[28px] xl:text-4xl text-darkNavyBlue';
    const text = 'flex flex-col wrap';
    const ptag = 'font-roboto text-base md:text-base font-normal leading-6 md:leading-7 text-justify ml-16';
    const span = 'text-darkNavyBlue text-xl font-semibold';

    let totalTickets = Array.isArray(data.tnumber) ? data.tnumber.reduce((acc, val) => acc + val, 0) : data.tnumber;


    Swal.fire({
        title: '<h4 class="' + title + '">Confirm Number</h4>',
        html: `    
                <div class="${text}">
                    <p class="${ptag}">Amount: <span class='${span}'>KES ${data.tamount.toLocaleString()}</span></p>
                    <p class="${ptag}">Ticket${totalTickets > 1 ? 's' : ''}:  <span class="${span}">${totalTickets}</span></p>
                    <p class="${ptag}">M-PESA No: <span class="${span}">${data.number}</span></p>
                </div>
            `,
        showCancelButton: true,
        cancelButtonText: "Cancel",
        cancelButtonColor: '#2A3580',
        confirmButtonColor: "#E76425",
        confirmButtonText: "Proceed with payment",
        reverseButtons: true,
        customClass: {
            actions: 'gap-6',
        }
    }).then((result) => {
        if (result.isConfirmed) {
            toast.loading('Making Payment Request', {
                position: 'top-right',
                duration: 3000,
                className: 'mt-24 ',

            })
            // Proceed with the payment
            Swal.fire({
                title: `<h4 class="${title}">Requesting Payment <span class='text-burntOrange font-roboto font-semibold text-4xl leading-10'>${data.number}</span></h4>`,
                html: "<p class='font-roboto text-base md:text-base font-normal leading-6 md:leading-7'>Wait 3 to 5 seconds and enter your MPESA Pin on the pop up..</p>",
                confirmButtonColor: "#E76425",
                confirmButtonText: "Ok",               

            })
            makePayment(data);
            console.log(data);
        }
    });


    const makePayment = (data) => {        
        fetch('http://client.gig.co.ke/api/make_payment_multiple', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),            
        })
        
            // .then((res) => {
            //     if (!res.ok) {
            //         throw new Error('Network response was not ok.');
            //     }
            //     return res.json();
            // })
            // .then(data => {
            //     let result_code = data.result_code;  
            //     let transactionid = data.data.transactionid;                       

            //     if (result_code == 0) {

            //         toast.success('Payment initiated successfully', {
            //             position: 'top-right',
            //             duration: 3000,
            //             className: 'mt-24 ',

            //         })

                    // Swal.fire({
                    //     title: `<h4 class="text-center font-roboto font-medium text-base md:text-2xl lg:text-[28px] xl:text-4xl text-darkNavyBlue">Enter MPESA Pin on</h4> `,
                    //     html: `
                    //     <div class='gap-4'>
                    //         <p class='text-burntOrange font-roboto font-semibold text-4xl leading-10'>${data.data.number}</p>
                    //         <p class='font-roboto text-base md:text-base font-normal leading-6 md:leading-7'>Wait 3 to 5 seconds and enter your MPESA Pin on the pop up.</p> 
                    //     </div>`,
                    //     showCancelButton: true,
                    //     confirmButtonColor: "#E76425",
                    //     confirmButtonText: "Done, I have made payment",
                    //     cancelButtonText: "No pop-up ? Pay manually via PayBill",
                    //     cancelButtonColor: '#2A3580',
                    //     reverseButtons: true,
                    // })
                    //.then((result) => {
        //                 if (result.isConfirmed) { 
        //                     //confirm if transaction went through and user made a payment                                                     
        //                     postConfirmPayment(transactionid);
        //                 }
        //                 else if (result.dismiss === Swal.DismissReason.cancel) {
        //                     Swal.fire({
        //                         title: '<h4 class="' + title + '">Pay manually using PayBill</h4>',
        //                         html: `
        //                         <div class='gap-4'>
        //                         <ol class='list-decimal list-inside'>
        //                              <li class='${ptag}'>Go to your M-PESA menu</li>
        //                              <li class='${ptag}'>Select Lipa na M-PESA</li>
        //                              <li class='${ptag}'>Select Pay bill</li>
        //                              <li class='${ptag}'>Enter Business Number: <span class="${span}">672683</span></li>
        //                              <li class='${ptag}'>Enter Account Number: <span class="${span}">G238583</span></li>
        //                              <li class='${ptag}'>Enter Amount: <span class="${span}">KES ${data.data.tamount.toLocaleString()}</span></li>
        //                              <li class='${ptag}'>Enter your M-PESA pin to make payment</li>                             
        //                         </ol>                        
                                
        //                         </div>`,
        //                         confirmButtonColor: "#E76425",
        //                         confirmButtonText: "Done! Confirm Payment",
        //                         showCancelButton: true,
        //                         cancelButtonText: "Cancel",
        //                         cancelButtonColor: '#2A3580',
        //                         reverseButtons: true,
        //                         customClass: {
        //                             actions: 'gap-4',
        //                         }
        //                     }).then((result) => {
        //                         if (result.isConfirmed) {
        //                             //check if payment was made using Paybill
        //                             postConfirmPayment(transactionid);
                                    
        //                         }
        //                         //write code to show it didn't work
        //                     })
        //                 }
        //             })
        //         } else {
        //             // Handle other result_code values or display an error message
        //             toast.error(`Could not initiate payment on ${data.data.number}`, {
        //                 position: 'top-right',
        //                 duration: 3000,
        //                 className: 'mt-24 ',
        //             })

        //             Swal.fire({
        //                 title: '<h4 class="' + title + '">Payment request failed</h4>',
        //                 html: `
        //                         <div class='gap-4'>
        //                         <ol class='list-decimal list-inside'>
        //                              <li class='${ptag}'>Go to your M-PESA menu</li>
        //                              <li class='${ptag}'>Select Lipa na M-PESA</li>
        //                              <li class='${ptag}'>Select Pay bill</li>
        //                              <li class='${ptag}'>Enter Business Number: <span class="${span}">672683</span></li>
        //                              <li class='${ptag}'>Enter Account Number: <span class="${span}">G238583</span></li>
        //                              <li class='${ptag}'>Enter Amount: <span class="${span}">KES ${data.data.tamount.toLocaleString()}</span></li>
        //                              <li class='${ptag}'>Enter your M-PESA pin to make payment</li>                             
        //                         </ol>                        
                                
        //                         </div>`,
        //                 confirmButtonText: "Try again",
        //                 cancelButtonText: "No pop-up ? Pay manually via PayBill",
        //                 cancelButtonColor: '#2A3580',
        //                 confirmButtonColor: "#E76425",
        //                 reverseButtons: true,
        //             }).then((result) => {
        //                 if (result.isConfirmed) {
        //                     //write code to retry payment using stk
        //                 }
        //                 else if (result.dismiss === Swal.DismissReason.cancel) {
        //                     //pay using paybill
        //                     Swal.fire({
        //                         title: '<h4 class="' + title + '">Pay Manually Using Paybill</h4>',
        //                         html: `
        //                                 <div class='gap-4'>
        //                                 <ol class='list-decimal list-inside'>
        //                                      <li class='${ptag}'>Go to your M-PESA menu</li>
        //                                      <li class='${ptag}'>Select Lipa na M-PESA</li>
        //                                      <li class='${ptag}'>Select Pay bill</li>
        //                                      <li class='${ptag}'>Enter Business Number: <span class="${span}">672683</span></li>
        //                                      <li class='${ptag}'>Enter Account Number: <span class="${span}">G238583</span></li>
        //                                      <li class='${ptag}'>Enter Amount: <span class="${span}">KES ${data.data.tamount.toLocaleString()}</span></li>
        //                                      <li class='${ptag}'>Enter your M-PESA pin to make payment</li>                             
        //                                 </ol>                        
                                        
        //                                 </div>`,
        //                         confirmButtonText: "Done, I have made payment",
        //                         cancelButtonText: "Cancel",
        //                         cancelButtonColor: '#2A3580',
        //                         confirmButtonColor: "#E76425",
        //                         reverseButtons: true,
        //                     }).then((result) => {
        //                         if (result.isConfirmed) {
        //                             //write code to check if payment is successful using stk
        //                         }else{
        //                             //error if stk isn't successful
        //                         }
        //                     })
        //                 }
        //             })
        //         }
        //     })
    }
}