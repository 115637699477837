import React from "react";
import { NavLink } from "react-router-dom";
// import poster from '../../images/dash_image.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faClock } from "@fortawesome/free-regular-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { formattedDate, truncate } from "../../util/Util";
import { capitalize } from "../../util/Util";

function EventCard({ event }) {
  return (
    <>
      <div className="w-48 h-[300px] sm:w-72 sm:h-[420px] flex-shrink-0 sm:max-w-sm bg-white border border-gray-200 rounded-lg shadow-md dark:border-gray-200 m-5 mb-30 transition duration-500 hover:opacity-50 relative overflow-hidden">
        <div className="border border-gray-200 dark:border-gray-20 relative">
          <NavLink to={`/events/${event.eventid}`} className="">
            <img
              className="h-[170px] w-48 rounded-lg sm:min-h-[288px] sm:min-w-[284px] sm:max-w-md sm:max-h-72 sm:w-[284px] object-fill"
              src={`https://client.gig.co.ke/eventpics/${event.eventid}_banner_pic`}
              alt="event poster"
            />
          </NavLink>  
          {/* <div className="absolute inset-0 flex  hover:opacity-100 opacity-0 hover:z-50 items-center justify-center transition-opacity duration-300" >
            <NavLink
              to={`/events/${event.eventid}`}
              className="absolute inset-0"
            >
              <button className="bg-blue-500 text-white px-4 py-2 rounded-lg ">
                Navigate
              </button>
            </NavLink>
          </div>         */}
        </div>

        <div className="p-2 flex flex-col space-y-1">
          <div className="float-left text-base text-gray-900 w-85">
            <div className="pl-1">
            <p className="block sm:hidden text-primaryBlue font-roboto font-medium text-[19px] leading-[28.5px]">
                {capitalize(truncate(event.eventname, 16))}
              </p>
              <p className="hidden sm:flex text-primaryBlue font-roboto font-medium text-[19px] leading-[28.5px]">
                {capitalize(truncate(event.eventname, 26))}
              </p>
            </div>
          </div>
          <div className="">
            <p className="flex flex-row space-x-3 pl-2">
              <span>
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  className="text-customGrey"
                />
              </span>
              <span className="font-roboto text-[13px] font-medium leading-relaxed text-customGrey pt-0.5">
                {formattedDate(event.eventstartdate)}
              </span>
            </p>
          </div>
          <div className="">
            <p className="flex flex-row space-x-3 pl-2">
              <span>
                <FontAwesomeIcon icon={faClock} className="text-customGrey" />
              </span>
              <span className="font-roboto text-[13px] font-medium leading-relaxed text-customGrey pt-0.5">
                {event.eventtime}
              </span>
            </p>
          </div>
          <div className="">
            <p className="flex flex-row space-x-3 pl-2.5">
              <span>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="text-customGrey"
                />
              </span>
              <span className="font-roboto text-[13px] font-medium leading-relaxed text-customGrey pt-0.5">
                {truncate(event.eventlocationname, 20)}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventCard;
