import React from 'react'

function OrangeButton({buttonName, height, width}) {

  const buttonStyle = {
    height: height || 'auto', // Default height is 'auto' if not provided
    width: width || 'auto',   // Default width is 'auto' if not provided
  };
  return (
    <>
     <button className="bg-customOrange text-white hover:text-white font-medium py-2 px-6 rounded-lg text-base hover:bg-orangeBrown"   style={buttonStyle}>{buttonName}</button>
    </>
  )
}

export default OrangeButton
